import React from 'react'
import PropTypes from 'prop-types'
import Navbar from './Navbar'
import Footer from './Footer'
import ScrollToTop from 'react-scroll-up'
import { graphql, useStaticQuery } from 'gatsby'
import Button from 'react-bootstrap/Button'
import BackgroundImage from 'gatsby-background-image'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'react-bootstrap'
import SEO from './SEO'
const Layout = ({ backgroundImage, galleryPage, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "aquarelles/aquarelles_4.JPG" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 40) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <div style={{ display: 'flex',
    minHeight: "100vh"}}>
      <SEO style={{ flex: "none" }} />
      <Navbar style={{ flex: "none" }} />

      {backgroundImage ? (
        <BackgroundImage fluid={imageData} style={{ flex: "auto" }}>
          <Container
            fluid
            className={`mt-5 pt-4 pb-5 ${galleryPage ? ' mx-0 px-0' : ''}`}
          >
            {children}
          </Container>
        </BackgroundImage>
      ) : (
        <Container fluid className="my-5 py-5" style={{ flex: "auto"}} >
          {children}
        </Container>
      )}

      <ScrollToTop showUnder={160} style={{ flex: "none" }}>
        <Button variant="info">Début</Button>
      </ScrollToTop>

      <Footer className="footer" style={{ flex: "none" }} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
