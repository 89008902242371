import React, { Component } from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { SocialIcon } from 'react-social-icons'
import 'bootstrap/dist/css/bootstrap.min.css'
class NavbarPage extends Component {
  state = {
    isOpen: false,
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <Navbar
        style={{
          fontSize: 'calc(1rem + 0.2vw)',
        }}
        bg="info"
        variant="dark"
        expand="xl"
        fixed="top"
      >
        <Navbar.Brand
          style={{ fontSize: 'calc(1rem + 0.5vw)' }}
          className="navbarbrand"
          href="/"
        >
          Le site de l'association <br /> Léon Hamonet
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link className="ml-4" href="/">
              Accueil
            </Nav.Link>
            <Nav.Link className="ml-4" href="/news">
              News
            </Nav.Link>
            <Nav.Link className="ml-4" href="/association">
              L'association
            </Nav.Link>
            <NavDropdown drop="down" className="ml-4" title="Le peintre">
              <NavDropdown.Item href="/le_peintre">Biographie</NavDropdown.Item>
              <NavDropdown.Item href="/analyse_peinture">
                Analyse de sa peinture
              </NavDropdown.Item>
              <NavDropdown.Item href="/famille">
                Léon par sa famille
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="ml-4" href="/galerie">
              Galerie
            </Nav.Link>
            <Nav.Link className="ml-4" href="/contact">
              Contact
            </Nav.Link>

            <SocialIcon
              className="ml-4"
              url="https://www.facebook.com/leonhamonet"
              bgColor="white"
              style={{
                width: 'calc(1rem + 0.5vw)',
                height: 'calc(1rem + 0.5vw)',
                marginTop: "11px",
              }}
            />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default NavbarPage
