import React from 'react'
import { Navbar, Col, Container, Row, Button, Icon } from 'react-bootstrap'
import { SocialIcon } from 'react-social-icons'

const Footer = () => {
  const styleFooter = {
    textAlign: 'center',
    float: 'none',
    display: 'block',
    color: 'white',
    fontSize:"0.7rem"
  }
  {
    /* <SocialIcon style={{height: 'calc(1rem + 0.7vw)',width:'calc(1rem + 0.7vw)'}}
            url="https://www.facebook.com/leonhamonet/"

/>*/
  }
  return (
    <Navbar
      style={styleFooter}
      bg="info"
      expand="lg"
      fixed="bottom"
      variant="dark"
    >
      <Col
        lg="auto"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >©Julien Petitpierre</Col>
    </Navbar>
  )
}

export default Footer
